<template>
  <gw-dialog v-if="obCurrency" v-model="dialog">
    <template #activator="{ on }">
      <currency-preview :item="obCurrency" color="primary" v-on="on" />
    </template>

    <template #title>
      <v-card-title>
        <sheet class="w-full" dense depressed>
          <v-row>
            <v-col cols="8" lg="10">
              <v-row>
                <v-col>
                  <form-field-date-picker
                    v-model="sDateFrom"
                    :max="dateFromMax"
                    label="date.from"
                  />
                </v-col>

                <v-col>
                  <form-field-date-picker
                    v-model="sDateTo"
                    :disabled="!sDateFrom"
                    :max="dateToMax"
                    :min="dateToMin"
                    label="date.to"
                  />
                </v-col>
              </v-row>

              <v-row v-if="!company">
                <v-col>
                  <v-switch
                    v-model="currentCompany"
                    :label="$t('current.company')"
                  />
                </v-col>
                <v-col v-if="userIsAdmin">
                  <company-select
                    v-model="iCompanyId"
                    :disabled="currentCompany"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" lg="2">
              <v-spacer />

              <v-btn color="primary" depressed icon @click="onSearch">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>

              <v-btn
                :disabled="!canClear"
                color="primary"
                depressed
                icon
                @click="onClear"
              >
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </sheet>
      </v-card-title>
    </template>

    <currency-rate-history
      v-if="dialog"
      :company="iCompanyId"
      :currency-id="obCurrency.id"
      :date-from="sDateFrom"
      :date-to="sDateTo"
      :search-key="sSearchKey"
    />
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import GwDialog from "@/components/common/GwDialog.vue";
import CurrencyRateHistory from "@/modules/currencyrates/components/CurrencyRateHistory.vue";
import { AppModule } from "@/store/app";
import CurrencyPreview from "@/modules/currencies/components/CurrencyPreview.vue";
import DateRange from "@/components/form/fields/DateRange.vue";
import dayjs from "dayjs";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";
import { isEmpty, uniqueId } from "lodash";
import CompanySelect from "@/modules/companies/components/CompanySelect.vue";
import { AuthModule } from "@/store/auth";

@Component({
  components: {
    CompanySelect,
    FormFieldDatePicker,
    DateRange,
    CurrencyPreview,
    CurrencyRateHistory,
    GwDialog,
  },
})
export default class CurrencyRateHistoryDialog extends Vue {
  @Prop(String) readonly currency!: string;
  @Prop(Number) readonly company!: number;

  dialog = false;
  currentCompany = false;
  sDateFrom = "";
  sDateTo = "";
  sSearchKey = "";
  iCompanyId = 0;

  get currencies() {
    return AppModule.currencies;
  }

  get obCompany() {
    return AppModule.company;
  }

  get userIsAdmin() {
    return AuthModule.isAdmin;
  }

  get obCurrency() {
    return this.currency && this.currencies.length
      ? this.currencies.find({ code: this.currency })
      : undefined;
  }

  /**
   * Returns max valid date for dateFrom input, based on dateTo or current date
   */
  get dateFromMax(): string {
    return this.sDateTo || dayjs().format();
  }

  /**
   * Returns min valid date for dateTo input, based on dateFrom
   */
  get dateToMin() {
    return this.sDateFrom;
  }

  /**
   * Validate max date for dateTo input, based on current date
   */
  get dateToMax() {
    return dayjs().format();
  }

  get canClear() {
    return (
      !isEmpty(this.sDateFrom) ||
      !isEmpty(this.sDateTo) ||
      (this.iCompanyId && this.iCompanyId !== this.company)
    );
  }

  onSearch() {
    if (this.sDateFrom && isEmpty(this.sDateTo)) {
      this.sDateTo = dayjs().format();
    }

    if (this.currentCompany && this.obCompany) {
      this.iCompanyId = this.obCompany.id;
    }

    this.sSearchKey = uniqueId("currency-rate-history");
  }

  onClear() {
    this.sDateTo = "";
    this.sDateFrom = "";
    this.currentCompany = false;
    if (this.iCompanyId && this.iCompanyId !== this.company) {
      this.iCompanyId = 0;
    }
    this.onSearch();
  }

  created() {
    this.onSearch();
  }

  mounted() {
    if (this.company) {
      this.iCompanyId = this.company;
    }
  }
}
</script>
